import moment from 'moment'
import { ListFormControl } from '../../sections/ListForm'
import ControlRequest from '../../utilities/ControlRequest'
import ControlTabs from '../../utilities/ControlTabs'
import FormEvents from '../../utilities/FormEvents'
import { Keys } from './constants'
import { FormPurchaseReceipt, ProductsForInput, StoragePage } from './types'

export const controlTabs = new ControlTabs()

export const storagePage: StoragePage = {
    purchaseReceipt: null,
    mode: 'creation',
    timezone: '',
    localCurrency: null,
    receipt_products: {}
}

export const formPurchaseReceipt = new FormEvents<FormPurchaseReceipt>(
    Keys.FORM,
    {
        company: null,
        warehouse: null,
        typeRC: null,
        shipment: '',
        exchangeRate: '',
        receiptDate: moment().toISOString(),
        files: [],
        comment: '',
        purchase: null,
        numberInvoiceShipping: '',
        numberInvoiceFinal: '',
        invoiceShippingDate: '',
        invoiceFinalDate: '',
        providerLocalAmount: '0.00'
    }
)

export const productsControl = new ListFormControl<ProductsForInput>(
    Keys.TABLE,
    [
        '#',
        'RQ',
        'Bodega destino',
        'Producto',
        'Precio Unit. Proveedor',
        'Cant. solicitada OC',
        'Cant. recepcionada en otras RC',
        'Cant. pendiente por recepcionar',
        'Cant. a recepcionar',
        'Confirmar'
    ]
)

export const reqWorkingPeriod = new ControlRequest()
export const reqWarehouse = new ControlRequest()
export const reqPurchase = new ControlRequest()
export const reqProducts = new ControlRequest()
